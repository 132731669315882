/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  color: var(--orderbird-color-text);
  font-family: var(--orderbird-font-family);
}

ion-button {
  --border-color:var(--orderbird-color-blue);
  --border-radius: 5px;
  --border-width: 1px;
  --border-style: solid;
  --background: var(--orderbird-color-blue);
  --background-hover: var(--orderbird-color-blue);
  --background-activated: var(--orderbird-color-blue);
  --color: var(--orderbird-color-white);
  color: var(--orderbird-color-white);
  --box-shadow: none;
}



.ion-button-action {
	border: 0px solid var(--orderbird-color-blue);
	--background: var(--orderbird-color-blue);
	--background-hover: var(--orderbird-color-blue);
	--background-activated: var(--orderbird-color-blue);
	--color: white;
	color: white;
  
	--box-shadow: none;
  }

ion-button ion-icon {
  color: var(--orderbird-color-blue);
}

ion-button[name*="textColor"],[color="danger"]{
  --border-width:0px;
}

ion-toggle {
  --handle-background: var(--orderbird-color-blue);
  --handle-background-checked: var(--orderbird-color-blue);
  --background: var(--orderbird-color-midgrey);
  --background-checked: var(--orderbird-color-midgrey);

}

.center {
    align-items: center;
    align-self: center;
    align-content: center;
    text-align: center;
}

.ion-color-split-button {
    --ion-color-base: var(--ion-color-split-button) !important;
    --ion-color-base-rgb: var(--ion-color-split-button-rgb) !important;
    --ion-color-contrast: var(--ion-color-split-button-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-split-button-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-split-button-shade) !important;
    --ion-color-tint: var(--ion-color-split-button-tint) !important;
}

.tooltip-Split{
        pointer-events: none;
        --background: rgb(150, 150, 150);
}

.tooltip-Split-fitcontent{
    pointer-events: none;
    --background: rgb(150, 150, 150);
    --width: fit-content;
}

.coupon-generate-modal-resized {
    .modal-wrapper {
        position: center;
        top: 0;
        left: 0;
        display: block;
        width: 40%;
        min-width: 300px;
        height:40%;
    };
    
}

:root {
        --ion-color-splitO: rgba(238, 117, 79, 1) !important;
        --ion-color-splitO-rgb: rgba(238, 117, 79, 1) !important;

}

.ion-color-splitO {
        --ion-color-base: var(--ion-color-splitO) !important;
        --ion-color-base-rgb: var(--ion-color-splitO-rgb) !important;
        --ion-color-contrast: var(--ion-color-splitO-contrast) !important;
        --ion-color-contrast-rgb: var(--ion-color-splitO-contrast-rgb) !important;
        --ion-color-shade: var(--ion-color-splitO-shade) !important;
        --ion-color-tint: var(--ion-color-splitO-tint) !important;
}
    

.info-pop-over{
    .popover-content{
        align-self: flex-end;
        
    }
}
.wideDateInput{
    height:100%;
    input {
    height:100%;
    border: none !important;
    background: transparent !important;
    width: 100% !important;
  }

   input::-webkit-calendar-picker-indicator {
       height:100%;
       background-size: 24px;
    right: 0px !important;
    position: absolute !important;
    width: 100% !important;
    color: rgba(204, 204, 204, 0) !important;
    display:flex;
    align-items: flex-end;
    align-content: flex-end;
    flex-direction: row-reverse;
    background-position: right;
}
input::-webkit-datetime-edit {
    display:flex;
    height:100%;
    align-items: center;
    margin-right: 8px;
}
}

.scrollHidden {
      .popover-content{
      overflow: hidden;
    }
}

.email-prompt-modal {
  --height: 20%;
}

@media screen and (min-width: 768px) {
  .qrcodepage-modal {
    align-items: flex-start;
    padding-top: 49px;
    --width: 531px;
    --min-height: 600px;
    --height: auto;
  }
}





@font-face {   
    font-family: 'Architects Daughter';   
    src: url('../src/assets/fonts/Architects_Daughter/ArchitectsDaughter-Regular.ttf');
  }
  @font-face {   
    font-family: 'Italiana';   
    src: url('../src/assets/fonts/Italiana/Italiana-Regular.ttf');
  }
  @font-face {   
    font-family: 'Barlow Condensed';   
    src: url('../src/assets/fonts/Barlow_Condensed/BarlowCondensed-Regular.ttf');
  }
  @font-face {   
    font-family: 'Fredoka';  
    src: url('../src/assets/fonts/Fredoka/static/Fredoka/Fredoka-Regular.ttf');
  }
  @font-face {   
    font-family: 'Nunito';   
    src: url('../src/assets/fonts/Nunito/static/Nunito-Regular.ttf');
  }
  @font-face {   
    font-family: 'Playfair Display';  
    src: url('../src/assets/fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf');
  }
  @font-face {   
    font-family: 'Quicksand';   
    src: url('../src/assets/fonts/Quicksand/static/Quicksand-Regular.ttf');
  }
  @font-face {   
    font-family: 'Syne Mono';  
    src: url('../src/assets/fonts/Syne_Mono/SyneMono-Regular.ttf');
  }
  @font-face {   
    font-family: 'Open Sans';  
    src: url('../src/assets/fonts/Open_Sans/OpenSans-Regular.ttf');
  }
  @font-face {   
    font-family: 'Reem Kufi';  
    src: url('../src/assets/fonts/Reem_Kufi/ReemKufi-Regular.ttf');
  }
  @font-face {   
    font-family: 'Baloo2';  
    src: url('../src/assets/fonts/baloo2/Baloo2-VariableFont_wght.ttf');
  }
  @font-face {   
    font-family: 'Copse';  
    src: url('../src/assets/fonts/copse/Copse-Regular.ttf');
  }
  @font-face {   
	font-family: 'Albert Sans';  
	src: url('../src/assets/fonts/Albert_Sans/AlbertSans-VariableFont_wght.ttf');
}
@font-face {   
	font-family: 'Libre Caslon';
	src: url('../src/assets/fonts/Libre_Caslon_Text/LibreCaslonText-Regular.ttf');
}
@font-face {   
	font-family: 'Montserrat';  
	src: url('../src/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}
@font-face {   
	font-family: 'Martel Sans';  
	src: url('../src/assets/fonts/Martel_Sans/MartelSans-Regular.ttf');
}
@font-face {   
	font-family: 'Lobster';   
	src: url('../src/assets/fonts/Lobster/Lobster-Regular.ttf');
  }

  //Alert shown when user wants to delete an icon that what previously selected
  .delete-alert-custom {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important; 
    padding: 20px !important; 
    border-radius: 10px !important;

    h2{
      color: var(--orderbird-color-text) !important;
      font-family: var(--orderbird-font-family) !important;
      font-size: 16px !important;
      text-align: center !important; 
    }  
    button{
      border-color:var(--orderbird-color-blue) !important;
      border-radius: 5px !important;
      border-width: 1px !important;
      border-style: solid !important;
      background: var(--orderbird-color-blue) !important;
      --background-hover: var(--orderbird-color-blue) !important;
      --background-activated: var(--orderbird-color-blue) !important;
      --color: var(--orderbird-color-white) !important;
      color: var(--orderbird-color-white) !important;
      box-shadow: none !important;
    }

    .alert-button-inner {
      color: var(--orderbird-color-white) !important;
    }

    .alert-button-group{
      display: flex !important;
      justify-content: center !important; 
      gap: 10px !important;
    }
  }

  